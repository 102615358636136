import React from 'react'
import { Typography, Box } from '@mui/material'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ExtendedPartfield, ExtendedTimeLog } from 'isoxml'
import { getISOXMLManager } from '../../commonStores/isoxmlFileInfo'
import { GridEntity } from './GridEntity'
import { TimeLogEntity } from './TimeLogEntity'
import { PartfieldEntity } from './PartfieldEntity'
import AgricultureIcon from '@mui/icons-material/Agriculture';
import GridOnIcon from '@mui/icons-material/GridOn';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';

export function ISOXMLFileStructure() {

    
    const isExpandedList = {}
    const isoxmlManager = getISOXMLManager()
    const tasks = isoxmlManager.rootElement.attributes.Task

    if (!tasks?.length) {
        return <Typography sx={{textAlign: 'center', p: 2}}>No tasks in this TaskSet</Typography>
    }
         


    return (<>
        {tasks.map(task => {
            const grid = task.attributes.Grid?.[0]
            const taskId = isoxmlManager.getReferenceByEntity(task).xmlId
            const partfieldId = task.attributes.PartfieldIdRef?.xmlId
            const partfield = task.attributes.PartfieldIdRef?.entity as ExtendedPartfield
            const isPartfieldWithGeom = partfield?.attributes.PolygonnonTreatmentZoneonly?.length > 0
            const timeLogs = (task.attributes.TimeLog || [])
                .filter((timeLog: ExtendedTimeLog) => timeLog.binaryData && timeLog.timeLogHeader)
            console.log("isExpanded: "+JSON.stringify(isExpandedList));
            const hasMachineData: boolean = timeLogs.length > 0;
            const hasGrid: boolean = grid!== undefined;
            const hasPartfield: boolean = partfield !== undefined;

            return (
                <Accordion key={taskId} sx={{p: 1}}>
                    <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            >
                        <GridOnIcon  sx={{m : 0.1}} color={hasGrid? 'primary' : 'secondary'}/>
                        <BookmarkBorderOutlinedIcon  sx={{m : 0.1}}   color={hasPartfield? 'primary' : 'secondary'}/>
                        <AgricultureIcon  sx={{m : 0.1}}  color={hasMachineData? 'primary' : 'secondary'}/>
                        <Typography
                            sx={{ pl:2, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}
                        >
                            {task.attributes.TaskDesignator || taskId}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {isPartfieldWithGeom && (
                            <Box sx={{pl: 2}}>
                                <PartfieldEntity
                                    partfield={partfield}
                                    partfieldId={partfieldId}
                                />
                            </Box>
                        )}
                        {grid && (
                            <Box sx={{pl: 2}}>
                                <GridEntity
                                    gridId={taskId}
                                    grid={grid}
                                />
                            </Box>
                        )}
                        {timeLogs.map(timeLog => {
                            const timeLogId = timeLog.attributes.Filename
                            return (
                                <Box sx={{pl: 2}} key={timeLogId}>
                                    <TimeLogEntity timeLogId={timeLogId} />
                                </Box>
                            )
                        })}
                    </AccordionDetails>
                </Accordion>
            )
        })}
    </>)
}